import axios from "axios";
import { makeAutoObservable } from "mobx";
import config from "../api-config.json";
import moment from "moment";

export default class InvitationStore{
    eventTitle:string = "";
    guestName:string = "";
    invitationId:string = "";
    loading:boolean = false;
    theme:string = "";
    time:string ="";
    date:string="";
    location:string="";
    expiryDate: Date = new Date("April 1, 2023 13:00:00");
    selectedGender:string ="";
    specialMessage:string="";
    invitationValid:boolean = false;
    gender:string="";

    constructor(){
        makeAutoObservable(this);
    }

    setMessage(val:string){
        this.specialMessage = val;
    }

    setGender(val:string){
        this.gender = val;
    }

    setInvitationId(val:string){
        this.invitationId = val;
    }

    setLoadingStatus(status:boolean){
        this.loading = status;
    }

    async setInvitationStatus(status:boolean){
        this.invitationValid = status;
    }

    submitInvitationResponse(answer:boolean){
        let response = {
            InvitationAccepted: answer,
            Gender: this.gender,
            SpecialMessage: this.specialMessage,
            InvitationId: this.invitationId
        };

        let endpoint:string = config.base_url+`/invitation`;
        const headers = {
            'Content-Type': 'application/json'
          }

        axios.post(endpoint,response,{headers:headers}).then(res=>{
            console.log(res.data);
        }).catch(err=>{
            console.log(err)
        })
    }

    setEventDetails(eventName:string,name:string, surname:string, location:string, theme:string, expiryDate: Date, eventDate: Date){
        this.eventTitle = eventName;
        this.guestName = name;
        this.location = location;
        this.expiryDate = expiryDate;
        this.time = moment(eventDate).format("hh:mm A");
        this.date = moment(eventDate).format('DD/MM/YYYY');
        this.theme = theme;
    }

    async getEventDetails(){
        let endpoint:string = config.base_url+`/invitation/${this.invitationId}`;
        axios.get(endpoint).then(response=>{
            console.log(response.data);
            if(response.data.status == 0){
                const {eventName,guestName,guestSurname,theme,location,date, expiryDateTime} = response.data.eventDetails;
                this.setEventDetails(eventName,guestName,guestSurname,location,theme,expiryDateTime,date)
                this.setInvitationStatus(true);
            }
            else{
                this.setInvitationStatus(false);
            }
            
        }).catch(err=>{
            console.log(err);
            this.setInvitationStatus(false);
        })
    }
}