import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {observer} from 'mobx-react';
import {useStore} from "../store/Store";
import moment from "moment";

const Invitation = ()=>{
  const {invitationStore} = useStore();
  const [expired, setExpiry] = useState(false);
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  let navigate = useNavigate();

  useEffect(()=>{
    countDown();
  },[]);

  const rsvp = async (answer: string)=>{
    if(answer == 'YES'){
      navigate(`/invitation/${invitationStore.invitationId}/gender`);     
    }
    else{
      invitationStore.submitInvitationResponse(false);
      navigate('/reject');
    }
  }
  const countDown = ()=>{
    var countDownDate = new Date("April 1, 2023 13:00:00").getTime();
    // Update the count down every 1 second
    var x = setInterval(function() {

      // Get today's date and time
      var currentDate = moment.now();
      var now = new Date().getTime();
        
      // Find the distance between now and the count down date
      var distance = countDownDate - now;
        
      // Time calculations for days, hours, minutes and seconds
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);
        
      // Output the result in an element with id="demo"
      setDays(days);
      setHours(hours);
      setMinutes(minutes);
      setSeconds(seconds);

      // If the count down is over, write some text 
      if (distance < 0) {
        clearInterval(x);
        setExpiry(true);
      }
    }, 1000);
  }
  return (
    <div className="w-full flex justify-start items-center">
      <div className='flex items-center justify-start flex-col w-full py-10'>
        <h1 className='md:text-8xl text-4xl text-slate-200'>Hey {invitationStore.guestName},</h1>
        <h1 className='md:text-8xl text-4xl text-slate-200'>Please join us for a</h1>
        <h1 className='md:text-8xl text-4xl text-slate-200'>Baby Shower</h1>
        <h1 className='md:text-8xl text-4xl text-slate-200'>Celebration</h1>

        <div className='flex flex-col items-center mt-10 font-semibold mb-10'>
          <p>HONOURING</p>
          <p>Herminah & Tebogo</p>
        </div>
        <div className='flex md:flex-row flex-col justify-start items-start md:space-x-2 px-3'>
          <div className='flex items-start font-semibold justify-center space-x-1'>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
            </svg>
            <h3>{invitationStore.date}</h3>
          </div>
          <div className='flex items-start font-semibold justify-center space-x-1'>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <h3>{invitationStore.time}</h3>
          </div>

          <div className='flex items-start font-semibold justify-center space-x-1'>
            <a href="https://goo.gl/maps/pVKJiRHiCZuXdMuR9">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
              </svg>
            </a>
            <a href="https://goo.gl/maps/pVKJiRHiCZuXdMuR9">
              <div>
                <h3>{invitationStore.location}</h3>
              </div>
            </a>
          </div>
        </div>

        <div className='flex flex-col items-center'>
        <h2 className='mt-5 font-bold'>THEME</h2>
        <h3>{invitationStore.theme}</h3>
        </div>
        <h2 className='mt-5 font-bold'>RSVP</h2>
        <div className="flex items-center justify-center space-x-1 md:text-2xl text-xl">
          <h5>{days} Days</h5>
          <h5>{hours} Hour(s)</h5>
          <h5>{minutes} Min</h5>
          <h5>{seconds} Seconds</h5>
        </div>
        {expired == false?(
          <div className="flex justify-center items-center md:space-x-2 space-x-1 px-2">
            <div onClick={()=> rsvp('NO')} className='mt-4 flex items-start font-semibold justify-center space-x-2 border py-2 px-2 rounded-md shadow bg-rose-800 border-rose-800  transition-colors cursor-pointer text-white'>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" stroke-linejoin="round" d="M7.5 15h2.25m8.024-9.75c.011.05.028.1.052.148.591 1.2.924 2.55.924 3.977a8.96 8.96 0 01-.999 4.125m.023-8.25c-.076-.365.183-.75.575-.75h.908c.889 0 1.713.518 1.972 1.368.339 1.11.521 2.287.521 3.507 0 1.553-.295 3.036-.831 4.398C20.613 14.547 19.833 15 19 15h-1.053c-.472 0-.745-.556-.5-.96a8.95 8.95 0 00.303-.54m.023-8.25H16.48a4.5 4.5 0 01-1.423-.23l-3.114-1.04a4.5 4.5 0 00-1.423-.23H6.504c-.618 0-1.217.247-1.605.729A11.95 11.95 0 002.25 12c0 .434.023.863.068 1.285C2.427 14.306 3.346 15 4.372 15h3.126c.618 0 .991.724.725 1.282A7.471 7.471 0 007.5 19.5a2.25 2.25 0 002.25 2.25.75.75 0 00.75-.75v-.633c0-.573.11-1.14.322-1.672.304-.76.93-1.33 1.653-1.715a9.04 9.04 0 002.86-2.4c.498-.634 1.226-1.08 2.032-1.08h.384" />
              </svg>
              <h3>Sorry, I can't make it</h3>
            </div>

            <div onClick={()=> rsvp('YES')} className='mt-4 flex items-start font-semibold justify-center space-x-2 border border-teal-600 py-2 px-2 rounded-md shadow bg-teal-800 transition-colors cursor-pointer text-white'>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6.633 10.5c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 012.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 00.322-1.672V3a.75.75 0 01.75-.75A2.25 2.25 0 0116.5 4.5c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 01-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 00-1.423-.23H5.904M14.25 9h2.25M5.904 18.75c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 01-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 10.203 4.167 9.75 5 9.75h1.053c.472 0 .745.556.5.96a8.958 8.958 0 00-1.302 4.665c0 1.194.232 2.333.654 3.375z" />
              </svg>
              <h3>Ooh yes, I'm coming!</h3>
            </div>
            </div>
        ):
        <div className='mt-2 bg-rose-800 py-6 px-5 rounded '>
          <h3 className='font-bold text-zinc-300'>Oops, RSVP window has closed</h3>
        </div>
        }
        
  
      </div>
    </div>
  );
}

export default observer(Invitation);