import React, {useState, useEffect} from 'react';
import { useNavigate,useLocation } from 'react-router-dom';
import {observer} from 'mobx-react';
import {useStore} from "../store/Store";

const Introduction = ()=>{
    const {invitationStore} = useStore(); 
    let navigate = useNavigate();
    const [invitationId,setInvitationId] = useState("");
    const { search } = useLocation();

    useEffect(()=>{
        const query = new URLSearchParams(search);
        const id = query.get('id');
        
        if(id!= null){
            setInvitationId(id);
        }
    },[])

    if(invitationStore.invitationValid){
        navigate(`/invitation/${invitationId}`);
    }

    const validateInvitationId = async ()=>{
        //store invitation id
        invitationStore.setInvitationId(invitationId);
        //Get Results
        await invitationStore.getEventDetails();
    }

    const inputChange = (e: any)=>{
        setInvitationId(e.target.value);
    }
    return (
    <div className="w-full flex justify-center items-center py-5" >
        <div className=' flex items-center justify-center flex-col w-full'>
            <h1 className='md:text-8xl text-4xl text-slate-200 mb-3 mt-[10%]'>Welcome to our baby shower</h1>
            <div className='flex md:flex-row flex-col items-center justify-center h-11 px-2'>
                <div className='h-full'>
                    <input value={invitationId} onChange={(e)=> inputChange(e)} type="text" className='active:border-rose-400 rounded-l py-2 px-3 text-center w-80 h-full' placeholder='Please Enter Invitation ID' />
                </div>
                <div onClick={()=>validateInvitationId()} className='md:mt-0 mt-1 cursor-pointer py-1 px-2 bg-rose-400 text-white shadow-md flex items-center justify-center md:rounded-r md:w-auto w-full rounded h-full '>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                    </svg>
                    <h3>Search Invitation</h3>
                </div>
            </div>
        </div>
    </div>
    );
}

export default observer(Introduction);