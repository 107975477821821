import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useStore } from '../store/Store';
import {observer} from 'mobx-react';

const ChooseGender = ()=>{
  const {invitationStore} = useStore();
  let navigate = useNavigate();
  const selectGender = (gender:string)=>{
    invitationStore.setGender(gender);
    navigate(`/invitation/${invitationStore.invitationId}/details`); 
  }

  const visitGiftRegistry = ()=>{

  }
  return (
    <div className="w-full flex justify-center items-center">
      <div className='flex items-center justify-start flex-col w-full min-h-full'>
        <div className='md:w-96 md:h-96 h-52 w-52 bg-contain bg-center border rounded-full mt-2 mb-14 shadow-lg border-rose-50' style={{backgroundImage: "url('/assets/images/GenderReveal.jpg')"}}></div>
        <h1 className='md:text-8xl text-4xl text-slate-200'>Hey {invitationStore.guestName},</h1>
        <h1 className='md:text-8xl text-4xl text-slate-200'>Let's talk about</h1>
        <h1 className='md:text-8xl text-4xl text-slate-200'><span>sex</span> Baby</h1>

        <div className='flex flex-col items-center mt-10 font-semibold text-slate-100'>
          <p>LET'S FIND OUT</p>
          <p>WHAT BABY THAKGALO WILL BE</p>
          <p className='mt-5 font-semibold underline'>SELECT THE GENDER BELOW:</p>
        </div>

        <div className="grid md:grid-cols-2 grid-cols-1 md:space-x-2 px-1 md:mb-0 mb-2">
          <div onClick={()=>selectGender('BOY')} className='cursor-pointer md:w-96 md:h-96 w-52 h-52 bg-contain bg-center border rounded mt-2 md:mb-14 shadow-lg hover:border-cyan-400 hover:shadow-2xl hover:border-6' style={{backgroundImage: "url('/assets/images/Boy.jpg')"}}></div>
          <div onClick={()=>selectGender('GIRL')} className='cursor-pointer md:w-96 md:h-96 w-52 h-52 bg-contain bg-center border rounded mt-2 md:mb-14 shadow-lg hover:border-pink-400 hover:shadow-2xl hover:border-6' style={{backgroundImage: "url('/assets/images/Girl.jpg')"}}></div>
        </div>
     
      </div>
    </div>
  );
}

export default observer(ChooseGender);