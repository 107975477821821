import React from 'react';
import { useStore } from '../store/Store';
import {observer} from 'mobx-react';

const Directions = ()=>{
  const{invitationStore} = useStore();
  const visitGiftRegistry = ()=>{
    window.open("https://www.myregistry.com/baby-registry/herminah-mothiba-and-tebogo-legodi-paulshof-gp/3688286/giftlist");
  }
  return (
    <div className="flex flex-col justify-between items-center border border-black">
      <div className='h-full bg-gradient-to-r from-blue-300 to-rose-300 opacity-90 flex items-center justify-start py-14 flex-col w-full'>
        <h1 className='md:text-8xl text-4xl text-slate-200'>Thank You!</h1>

        <div onClick={()=> visitGiftRegistry()} className='mt-8 flex items-start font-semibold justify-center space-x-2 py-2 px-2 rounded-md shadow bg-rose-800 hover:bg-rose-600 transition-colors cursor-pointer text-white'>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M21 11.25v8.25a1.5 1.5 0 01-1.5 1.5H5.25a1.5 1.5 0 01-1.5-1.5v-8.25M12 4.875A2.625 2.625 0 109.375 7.5H12m0-2.625V7.5m0-2.625A2.625 2.625 0 1114.625 7.5H12m0 0V21m-8.625-9.75h18c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125h-18c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z" />
          </svg>

          <h3>Check Our Gift Registry</h3>
        </div>

        <div className='flex flex-col justify-start items-start mt-10 space-y-2 text-black px-4 py-3 max-w-screen-md:text-2xl text-lg'>
          <div className='flex items-center font-semibold justify-center space-x-1 '>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <h3>13:00</h3>
          </div>
          <div className='flex items-center font-semibold justify-center space-x-1'>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z" />
            </svg>
            <h3>6 MAY 2023</h3>
          </div>
          <div className='flex items-center font-semibold justify-center space-x-1'>
            <a href="https://goo.gl/maps/pVKJiRHiCZuXdMuR9">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M15 10.5a3 3 0 11-6 0 3 3 0 016 0z" />
                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1115 0z" />
              </svg>
            </a>
            <a href="https://goo.gl/maps/pVKJiRHiCZuXdMuR9">
              <div>
                <h3>1 Twin River Estates,1 Jan Smuts Ave, Centurion, 0062</h3>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div className='w-full'>
        <div className='w-full'>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3589.404403795454!2d28.2271372!3d-25.8890731!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e95666b399b5bc3%3A0x989385208c4e559f!2sRiver%20Meadow%20Manor!5e0!3m2!1sen!2sza!4v1679067967243!5m2!1sen!2sza" className='w-full' height="450"  loading="lazy"></iframe>
        </div>
      </div>
  
    </div>

  );
}

export default observer(Directions);