import React,{useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { useStore } from '../store/Store';
import {observer} from 'mobx-react';

const Details = ()=>{
  const {invitationStore} = useStore();
  let navigate = useNavigate();

  const submitMessage = ()=>{
    invitationStore.submitInvitationResponse(true);
    navigate(`/invitation/${invitationStore.invitationId}/directions`);     
    }
    return (
      <div className=" w-full">
        <div className='flex items-center justify-start  flex-col w-full min-h-full py-8'>
          <h1 className='md:text-8xl text-4xl text-slate-200'>Leave a Special Message</h1>
          <h1 className='md:text-8xl text-4xl text-slate-200'>for the Parents & the Baby</h1>
  
          <div className='flex flex-col items-center mt-10 font-semibold mb-10 md:w-1/2 w-full px-3'>
            <textarea onChange={(e)=>invitationStore.setMessage(e.target.value)} className='rounded p-1 w-full' rows={5} placeholder="Please type message"></textarea>
            <div onClick={()=> submitMessage()} className='justify-center items-center md:w-auto w-full mt-1 flex font-semibold  space-x-2 border py-2 px-2 rounded-md shadow bg-teal-600 transition-colors cursor-pointer text-white'>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5" />
              </svg>
              <h3>Submit Message</h3>
            </div>
          </div>
    
        </div>
      </div>
    );
}

export default observer(Details);