import React from 'react';
import { Outlet } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const Home = ()=>{
    const navigate = useNavigate();

    const home = ()=>{
        navigate('/')
    }
    return(
        <div className='w-full min-h-[calc(100vh)] flex flex-col justify-between'>
            <div className=' flex-1 flex flex-col'>
                <header className='py-3 px-2 bg-slate-800 text-zinc-300 h-[70px]'>
                    <nav className='flex flex-row justify-center'>
                        
                        <div className='flex items-center justify-center'>
                            <h1 className='font-bold text-2xl'>Tebogo & Herminah's Baby Shower</h1>
                        </div>
                       
                        {/* <div className='flex items-center justify-center'>
                            <h1 className='font-bold text-2xl'>Register</h1>
                        </div> */}
                    </nav>
                </header>
                <div className='bg-cover bg-center flex-1' style={{backgroundImage: "url('./assets/images/clouds.jpeg')"}}>
                    <div className='bg-gradient-to-r from-blue-300 to-rose-300 opacity-90 min-h-[calc(100vh-120px)]'>
                        <Outlet/>
                    </div>
                </div>
            </div>
            <div className='bg-slate-800 h-[50px] w-full'></div>
        </div>
    )
}

export default Home;