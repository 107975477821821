import React from 'react';
import {Route, Routes} from 'react-router-dom';
import ChooseGender from './pages/ChooseGender';
import Details from './pages/Details';
import Directions from './pages/Directions';
import Home from './pages/Home';
import Introduction from './pages/Intro';
import Invitation from './pages/Invitation';
import PageNotFound from './pages/PageNotFound';
import Rejection from './pages/Reject';

function App() {

  return (
    <div>
      <Routes>
        <Route path='/' element={<Home/>}>
          <Route index element={<Introduction/>}/>
          {/* <Route path='home' element={<Introduction/>}/> */}
          <Route path='invitation/:id'>
            <Route index element={<Invitation/>}/>
            <Route path='/invitation/:id/gender' element={<ChooseGender/>}/>
            <Route path='/invitation/:id/details' element={<Details/>}/>
            <Route path='/invitation/:id/directions' element={<Directions/>}/>
          </Route>
          <Route path='reject' element={<Rejection/>}/>
          <Route path='*' element={<PageNotFound/>}/>
        </Route>
      </Routes>
    </div>
  );
}

export default App;
