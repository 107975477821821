import React from 'react';

const Rejection = ()=>{
    return (
        <div className=" w-full bg-cover bg-center flex justify-center items-center">
            <div className='flex items-center justify-center flex-col w-full min-h-full py-5'>
                <h2 className='md:text-8xl text-xl-4xl mt-10'>Thanks for your feedback, cheers!</h2>
            </div>
        
        </div>
    );
}

export default Rejection;