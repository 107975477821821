import {useContext, createContext} from 'react';
import InvitationStore from './InvitationStore';

//Create Store Container
export const store = {
    invitationStore: new InvitationStore()
};

export const StoreContext = createContext(store);
export function useStore(){
    return useContext(StoreContext);
};